* {
  font-family: "Poppins", sans-serif;
  margin: 0;
}

body {
  background-color: #121212;
  width: 100%;
}

textarea:focus,
input:focus {
  outline: none;
}

input {
  height: 30px;
}

input,
textarea,
button {
  border-radius: 5px;
  border: none;
  padding: 0px 10px;
}

textarea {
  padding: 10px;
}

button:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #383838;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
